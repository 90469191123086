@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,700&display=swap');

.login {
  min-height: 100vh;
}

#nav-items {
  font-weight: 300 !important;
}

.menu-closed {
  max-height: 1000px;
  opacity: 1;
  overflow: hidden;
}

.m-main-menu{
  margin-top: 0.3rem;
}

.img-error{
  height: 30rem;
}

@media only screen and (max-width: 1024px) {
  .menu-closed {
    max-height: 0;
    transition: all 0.4s ease-out;
    opacity: 0;
    overflow: hidden;
  }

  .menu-open {
    max-height: 1000px;
    opacity: 1;
    transition: all 0.4s ease-in;
  }

  .img-error{
    height: 22rem;
  }
}
